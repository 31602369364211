<template>
  <CRow>
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader> VENTA DE LOTE </CCardHeader>
        <CCardBody>
          <CTabs :active-tab.sync="activeTab">
            <CTab active>
              <template slot="title">
                <CIcon name="cil-calculator" /> {{tabs[0]}}
              </template>
              <cQuotation></cQuotation>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cilCheck" /> {{tabs[1]}}
              </template>
              <cSale></cSale>
            </CTab>
          </CTabs>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import cQuotation from '../../components/sale/quotation.vue';
import cSale from '../../components/sale/sale.vue'
export default {
    components:{cQuotation,cSale},
    data () {
    return {
      tabs: [
        'Por cotización',
        'Venta directa',        
      ],
      activeTab: 0
    }
  }
};

</script>

<style lang="scss" scoped>
</style>