<template>
  <CRow>
    <CCol xs="12" lg="12">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="12">
              <CRow>
                <CCol xs="4" lg="4">
                  <CInput
                    label="N° de cotización"
                    placeholder="Digite número de cotización"
                    v-model="quotation"
                    @keypress.enter="mtd_getquotation"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" xs="12" lg="6">
                  <CInput
                    label="Cliente"
                    horizontal
                    autocomplete="off"
                    v-model="client.name"
                    readonly
                  />
                </CCol>
                <CCol sm="12" xs="12" lg="6">
                  <CInput
                    label="Dni"
                    horizontal
                    autocomplete="off"
                    v-model="client.dni"
                    readonly
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" sm="12" lg="6">
                  <CInput
                    label="Correo"
                    horizontal
                    autocomplete="off"
                    v-model="client.email"
                    readonly
                  />
                </CCol>
                <CCol xs="12" lg="6" sm="12">
                  <CInput
                    label="Dirección"
                    horizontal
                    autocomplete="off"
                    v-model="client.address"
                    readonly
                  />
                </CCol>
              </CRow>
            </CCol>

            <CCol md="12">
              <CRow v-if="project.name != null" sm="12" lg="12" md="12">
                <CCol xs="0" sm="0" lg="2"> </CCol>
                <CCol xs="12" sm="12" lg="8">
                  <CCard>
                    <CCardHeader class="card-detail">
                      <label class="detail"> Detalle de lote </label>
                    </CCardHeader>
                    <table
                      class="
                        table
                        table-striped
                        table-fixed
                        table-hover
                        table-bordered
                      "
                    >
                      <thead class="table-succcess">
                        <tr>
                          <th>
                            <div>Proyecto</div>
                          </th>
                          <th>
                            <div>Etapa/Manzana</div>
                          </th>
                          <th>
                            <div>Lote</div>
                          </th>
                          <th>
                            <div>Metraje</div>
                          </th>
                          <!-- <th>
                            <div>Precio</div>
                          </th> -->
                          <th
                            v-if="project.amount_discount == '0.00'"
                            style="display: none"
                          >
                            <div>Descuento</div>
                          </th>
                          <th v-if="project.amount_discount != '0.00'">
                            <div>Descuento</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="table-success">
                          <td class="">{{ project.name }}</td>
                          <td class="">{{ project.street }}</td>
                          <td class="">{{ project.lot }}</td>
                          <td class="">{{ project.footage }}</td>

                          <td v-if="project.amount_discount != '0.00'">
                            S/{{ project.amount_discount }}
                          </td>
                          <td
                            v-if="project.amount_discount == '0.00'"
                            style="display: none"
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </CCard>
                </CCol>

                <!-- forma pago -->
                <CCol md="12" style="padding: 0px">
                  <CRow sm="12" lg="12" md="12">
                    <CCol xs="0" sm="0" lg="2"></CCol>
                    <CCol xs="12" sm="12" lg="8">
                      <CCol md="12">
                        <CCards
                          icon="fa fa-money-bill-alt"
                          caption="Detalle de venta"
                          btn_name="ocultar"
                          btn_name1="ocultar"
                        >
                          <CCardBody>
                            <CRow>
                              <CCol sm="5">
                                <CInput
                                  label="Fecha"
                                  horizontal
                                  type="date"
                                  autocomplete="off"
                                  v-model="sale.date"
                                />
                              </CCol>
                              <CCol sm="7">
                                <CInput
                                  label="Hora"
                                  horizontal
                                  type="time"
                                  autocomplete="off"
                                  v-model="sale.time"
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol sm="5">
                                <CInput
                                  label="Inicial"
                                  horizontal
                                  autocomplete="off"
                                  v-model="sale.amount_initial"
                                  @keyup="process"
                                  placeholder="0.00"
                                  disabled="disabled"
                                />
                              </CCol>
                              <CCol sm="7">
                                <CInput
                                  :label="sale.type_discount == 1? 'Descu. (%)': 'Descu. (S/)'"
                                  horizontal
                                  autocomplete="off"
                                  v-model="sale.amount_discount"
                                  readonly
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol sm="3">
                                <cSelectForm
                                  label="N° Cuotas"
                                  :options="data_numcuotas"
                                  placeholder="N° Cuotas"
                                  :value.sync="form_add_cuotas.id"
                                  @change="load_montos"
                                >
                                </cSelectForm>
                              </CCol>
                              <CCol sm="3">
                                <CInput
                                  label="Monto Total"
                                  :value.sync="form_add_cuotas.amounttotal"
                                  disabled="disabled"
                                />
                              </CCol>
                              <CCol sm="3">
                                <CInput
                                  label="Monto Pendiente"
                                  :value.sync="form_add_cuotas.pending"
                                  disabled="disabled"
                                />
                              </CCol>
                              <CCol sm="3">
                                <CInput
                                  label="Cuota"
                                  :value.sync="form_add_cuotas.amountcuota"
                                  disabled="disabled"
                                />
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol sm="5">
                                <CInput
                                  label="Fecha de pago primera cuota"
                                  type="date"
                                  autocomplete="off"
                                  v-model="sale.date_initial"
                                  @keyup="process"
                                />
                              </CCol>
                              <CCol sm="5">
                                <label class="radio-label"
                                  >Firmó contrato: </label
                                ><br />
                                <input
                                  class=""
                                  id="radio1"
                                  type="radio"
                                  value="1"
                                  v-model="sale.contract"
                                /><label class="radio-input" for="radio1"
                                  >Sí
                                </label>
                                <input
                                  class=""
                                  id="radio2"
                                  type="radio"
                                  value="2"
                                  v-model="sale.contract"
                                /><label class="radio-input" for="radio2"
                                  >No
                                </label>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCards>
                      </CCol>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="12">&nbsp;</CCol>
                <CCol md="12" style="text-align: center">
                  <button
                    style="padding: 5px; width: 22em; display: inline-block"
                    class="form-control btn-save"
                    :disabled="button"
                    color="primary"
                    @click="mtd_save_sale()"
                  >
                    <i class="fas fa-save"></i>&nbsp; REGISTRAR
                  </button>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CCards from "../../components/shared/containers/Cards.vue";
export default {
  components: { cSelectForm, CCards },
  data() {
    return {
      quotation: null,
      prices:[],
      client: {
        id: null,
        name: null,
        dni: null,
        address: null,
        email: null,
      },

      project: {
        project_streetblock_lot_id: null,
        user_id: null,
        name: null,
        street: null,
        lot: null,
        footage: null,     

        amount_discount: null,
        amount_pending: null,
        percentage_24: null,
        percentage_36: null,
      },
      sale: {
        date: "",
        time: "",
        type_discount:null,
        amount_initial: "",
        date_initial: "",
        contract: "",
        quotation: "",
      },
      data_numcuotas: [],
      form_add_cuotas: {
        id: "",
        amounttotal:0,
        pending:0,
        amountcuota:0,
        state: "",
      },
      data_amount_total: [],
      quot: {
        montototal_month_12: "",
        montomes_month_12: "",
        montototal_month_24: "",
        montomes_month_24: "",
        montototal_month_36: "",
        montomes_month_36: "",
      },
    };
  },
  computed: {
    button() {
      if (
        this.sale.date != "" &&
        this.sale.contract != "" &&
        this.sale.amount_initial != "" &&
        this.form_add_cuotas.id != "" &&
        this.sale.date_initial != ""
      )
        return false;
      return true;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getquotation: function () {
      if (this.quotation != null && this.quotation != "") {
        this.get({
          url:
            this.$store.getters.get__url +
            "/quotation/" +
            this.quotation +
            "/show",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.state == 0) {
              let actual = new Date();
              let fecha;
              fecha =
                actual.getFullYear() +
                "-" +
                ("0" + (actual.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + actual.getDate()).slice(-2);
              this.sale.date = fecha;
              var h = ("0" + actual.getHours()).slice(-2);
              var m = ("0" + actual.getMinutes()).slice(-2);
              this.sale.time = h + ":" + m;
              this.client.id = response.data.client_id;
              this.client.name =
                response.data.client.last_name +
                " " +
                response.data.client.name;
              this.client.dni = response.data.client.dni;
              this.client.address = response.data.client.address;
              this.client.email = response.data.client.email;
              this.project.user_id = response.data.user_id;
              this.project.name =
                response.data.project_streetblock_lot.project_streetblock.project.name;
              this.project.street =
                response.data.project_streetblock_lot.project_streetblock.street_blocks.name;
              this.project.lot = response.data.project_streetblock_lot.lot.name;
              this.project.footage =
                response.data.project_streetblock_lot.footage + "m²";
              this.project.project_streetblock_id =
                response.data.project_streetblock_lot.project_streetblock_id;              
              this.project.amount_initial = response.data.amount_initial;
              this.project.amount_discount = response.data.type_discount== 1? (response.data.amount_discount*response.data.prices[0].amount)/100 : response.data.amount_discount;
              this.project.percentage_24 =
                response.data.project_streetblock_lot.percentage_24;
              this.project.percentage_36 =
                response.data.project_streetblock_lot.percentage_36;
              let arr = [];
              arr = [
                { value: 0, label: "N° Cuotas" },
                { value: 12, label: "12" },
                { value: 24, label: "24" },
                { value: 36, label: "36" },
              ];
              this.data_numcuotas = arr;
              this.sale.type_discount = response.data.type_discount;
              this.sale.amount_discount = response.data.amount_discount;
              this.sale.amount_initial = this.project.amount_initial;
              this.prices = response.data.prices;              
              this.process();
              bus.$emit("alert", {
                color: "success",
                message: response.message,
              });
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.message,
              });
            }
          })
          .catch((errors) => {});
      } else {
        bus.$emit("alert", {
          color: "danger",
          message: "Digite un número de cotización",
        });
      }
    },
    process: function () {
      this.prices.forEach(element => {
          /** descuento */
          let descuento=0;          
          if (this.sale.type_discount == 1) {
            descuento = parseFloat(parseFloat(element.amount) * parseFloat(this.sale.amount_discount) / 100);
          }else{
            descuento = parseFloat(this.sale.amount_discount);
          }          
          element.pending = parseFloat(element.amount - descuento - this.sale.amount_initial).toFixed(2);
          element.cuota = parseFloat(element.pending / element.cuota).toFixed(2);
        });
        
    },
    load_montos: function () {
      this.prices.forEach(element => {
        if(element.quotation_month == this.form_add_cuotas.id ){       
              this.form_add_cuotas.amounttotal=element.amount;
              this.form_add_cuotas.pending=element.pending;
              this.form_add_cuotas.amountcuota=element.cuota;
          }
      });
    },

    mtd_save_sale: function () {
      let elementos;
      elementos = {
        quotation_id: this.quotation,
        date: this.sale.date,
        time: this.sale.time,
        user_id: this.$store.getters.get__user_id,
        client_id: this.client.id,
        project_streetblock_lot_id: this.project.project_streetblock_id,
        amount_total: this.form_add_cuotas.amounttotal,
        amount_initial: this.sale.amount_initial,
        type_discount: this.sale.type_discount,
        amount_discount: this.project.amount_discount,
        amount_pending: this.form_add_cuotas.pending,
        date_initial: this.sale.date_initial,
        contract: this.sale.contract,
        quotation: this.form_add_cuotas.id,
        state: 1,
      };
      this.post({
        url: this.$store.getters.get__url + "/sale/store",
        token: this.$store.getters.get__token,
        params: elementos,
      })
        .then((response) => {
          if (response.state == 0) {
            (this.quotation = null),
              (this.client = {
                name: null,
                dni: null,
                address: null,
                email: null,
              });
            this.project = {
              project_streetblock_lot_id: null,
              name: null,
              street: null,
              lot: null,
              footage: null,
              amount_total: null,
              amount_discount: null,
              amount_pending: null,
              percentage_24: null,
              percentage_36: null,
            };
            this.sale = {
              date: "",
              time: "",
              amount_initial: "",
              date_initial: "",
              contract: "",
              quotation: "",
            };
            this.data_numcuotas = [];

            this.data_amount_total = [];
            this.quot = {
              montototal_month_12: "",
              montomes_month_12: "",
              montototal_month_24: "",
              montomes_month_24: "",
              montototal_month_36: "",
              montomes_month_36: "",
            };
            this.form_add_cuotas = {
              id: "",
              state: "",
            };
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.card-pay {
  padding-left: 26%;
}

.card-detail {
  background-color: #4db6ac;
}
.radio-label {
  position: relative;
  margin-bottom: 5px;
  padding: 5px;
  display: contents;
}
.radio-input {
  margin-bottom: 5px;
  padding-left: 2px;
  padding-right: 15px;
}
.btn-save {
  background-color: #321fdb;
  color: white;
}
.btn-save:disabled {
  background-color: #adc7ec;
  color: #90add7;
  border: 1px solid #999999;
}
.btn-save:enabled:hover {
  border: 1px solid #4887e1;
  background-color: #4887e1;
  color: #ffffff;
  padding: 5px 10px;
}
/** */
.detail {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.card-detail {
  background-color: #4db6ac;
}
</style>