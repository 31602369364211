<template>
  <CRow>
    <CCol xs="12" lg="12">
      <CCard>
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <CRow>
                <CCol sm="6">
                  <div class="input-group">
                    <label
                      class="col-form-label col-sm-3"
                      style="text-align: left; padding-left: 0px"
                      for="Seleccione Cliente"
                      >Cliente</label
                    >
                    <select
                      placeholder="Seleccione Cliente"
                      v-model="form_add_cliente.id"
                      class="form-control"
                      @change="load_data_client"
                    >
                      <option value="0" disabled>{{ vue.text_select }}</option>
                      <option
                        v-for="item in data_clients"
                        :value="item.value"
                        v-bind:key="item.label"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <button
                        type="button"
                        v-c-tooltip.hover="{
                          content: `Nuevo Cliente`,
                        }"
                        :class="btnClasses"
                        @click="btn_addclient"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </CCol>
                <CCol sm="6">
                  <CInput
                    label="DNI"
                    horizontal
                    placeholder="DNI"
                    disabled="disabled"
                    v-model="clients.dni"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Telefono"
                    horizontal
                    placeholder="Telefono"
                    disabled="disabled"
                    v-model="clients.phone"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    label="Email"
                    horizontal
                    placeholder="Email"
                    disabled="disabled"
                    v-model="clients.email"
                  />
                </CCol>
              </CRow>
            </div>
            <div class="col-md-12">
              <CRow sm="12" lg="12" md="12">
                <CCol xs="0" sm="0" lg="2"> </CCol>
                <CCol xs="12" sm="12" lg="8">
                  <CCard>
                    <CCardHeader class="card-detail">
                      <label class="detail"> Detalle de lote </label>
                    </CCardHeader>
                    <table
                      class="
                        table
                        table-striped
                        table-fixed
                        table-hover
                        table-bordered
                      "
                    >
                      <thead class="table-succcess">
                        <tr>
                          <th width="30%">
                            <div>Proyecto</div>
                          </th>
                          <th width="30%">
                            <div>Etapa/Manzana</div>
                          </th>
                          <th width="10%">
                            <div>Lote</div>
                          </th>
                          <th width="15%">
                            <div>Metraje</div>
                          </th>
                          <th width="15%">
                            <div>Precio</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="table-success">
                          <td class="">
                            <cSelectForm
                              :options="data_projects"
                              placeholder="Escoja un Proyecto"
                              :value.sync="form_add_project.id"
                              @change="load_streetblocks"
                            />
                          </td>
                          <td class="">
                            <cSelectForm
                              :options="data_streetblocks"
                              placeholder="Escoja una Etapa/Manzana"
                              :value.sync="form_add_streetblocks.id"
                              @change="load_lots"
                              :disabled="vue.estado1 == 1 ? true : false"
                            />
                          </td>
                          <td class="" >
                            <cSelectForm
                              :options="data_lots"
                              placeholder="Escoja un Lote"
                              :value.sync="form_add_lots.id"
                              @change="load_info"
                              :disabled="vue.estado2 == 1 ? true : false"
                            />
                          </td>
                          <td class="">
                            {{ project.footage }} m²
                          </td>
                          <td class="">
                            S/ {{ project.total }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CCard>
                </CCol>
              </CRow>
            </div>

            <!-- Forma Pago -->
            <div class="col-md-12" style="padding: 0px">
              <CRow sm="12" lg="12" md="12">
                <CCol xs="0" sm="0" lg="2"> </CCol>
                <CCol xs="12" sm="12" lg="8">
                  <div class="col-md-12">
                    <CCards
                      icon="fa fa-money-bill-alt"
                      caption="Detalle de venta"
                      btn_name="ocultar"
                      btn_name1="ocultar"
                      :button="cp_none"
                    >
                      <div class="col-md-12">
                        <CRow>
                          <CCol sm="6">
                            <CInput
                              label="Fecha"
                              horizontal
                              type="date"
                              v-model="sale.date"
                            />
                          </CCol>
                          <CCol sm="6">
                            <CInput
                              label="Hora"
                              horizontal
                              type="time"
                              placeholder="00:00:00"
                              v-model="sale.departure_time"
                            />
                          </CCol>
                        </CRow>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <CInput
                              type="number"
                              label="Inicial"
                              v-model="vue.amount_initial"
                              @keyup="process"
                              :disabled="vue.estado3 == 1 ? true : false"
                            />
                          </div>
                          <div class="col-md-6">
                           <label>{{ vue.change_discount == true? 'Descuento en %': 'Descuento en S/' }}</label>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox"
                                    :v-model="vue.change_discount"                                                                  
                                  />
                                </div>
                              </div>
                              <input
                                type="text"                               
                                v-model="vue.amount_discount"
                                class="form-control border-form-control"
                                  @keyup="process"
                                :disabled="vue.estado3 == 1 ? true : false"
                               
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-3">
                            <cSelectForm
                              label="N° Cuotas"
                              :options="data_numcuotas"
                              placeholder="N° Cuotas"
                              :value.sync="form_add_cuotas.id"
                              :disabled="vue.estado4 == 1 ? true : false"
                              @change="load_montos"
                            />
                          </div>
                          <div class="col-md-3">
                            <CInput
                              label="Monto Total"
                              :value.sync="form_add_cuotas.amounttotal"
                              disabled="disabled"
                            />
                          </div>
                           <div class="col-md-3">
                            <CInput
                              label="Monto Pendiente"
                              :value.sync="form_add_cuotas.pending"
                              disabled="disabled"
                            />
                          </div>
                          <div class="col-md-3">
                            <CInput
                              label="Cuota"
                              :value.sync="form_add_cuotas.amountcuota"
                              disabled="disabled"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <CInput
                              type="date"
                              placeholder="Fecha Pago Primera Cuota"
                              label="Fecha Pago Primera Cuota"
                              v-model="sale.date_initial_fee"
                              :disabled="vue.estado4 == 1 ? true : false"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="radio-label">Firmó contrato: </label
                            ><br />
                            <input
                              id="radio1"
                              type="radio"
                              value="1"
                              v-model="sale.contract"
                            /><label class="radio-input" for="radio1"
                              >Sí
                            </label>
                            &nbsp;
                            <input
                              id="radio2"
                              type="radio"
                              value="2"
                              v-model="sale.contract"
                            /><label class="radio-input" for="radio2"
                              >No
                            </label>
                          </div>
                        </div>
                      </div>
                    </CCards>
                  </div>
                </CCol>
              </CRow>
            </div>

            <CCol sm="12">&nbsp;</CCol>
            <CCol sm="12" style="text-align: center">
              <button
                type="button"
                style="padding: 5px; width: 22em"
                :class="btnClasses"
                :disabled="button"
                @click="save_sale"
              >
                <i class="fas fa-save"></i>&nbsp; REGISTRAR
              </button>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- modal Cliente -->
    <cModalClient
      :title="modal.title"
      :boo_modal="modal.modal_form"
      :item="modal.item"
      @close_modal="close_addclient"
      @mtd_action="mtd_action_client"
    >
    </cModalClient>
  </CRow>
</template>

<script>
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CCards from "../../components/shared/containers/Cards.vue";
import cModalClient from "../../views/mantenance/modal_client.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { cSelectForm, CCards, cModalClient },
  data() {
    return {
      prefix: "sale",
      vue: {
        estado1: 1,
        estado2: 1,
        estado3: 1,
        estado4: 1,
        amount_discount: 0,
        amount_initial: 0,
        amount_pending: 0,
        amounttotal_discount: 0,
        change_discount: false,
        text_select: "Seleccione Cliente...",
        tipo_desc: "Descuento en S/.",
      },
     
      data_clients: [],
      form_add_cliente: {
        id: "",
      },
      clients: {
        id: "",
        name: "",
        dni: "",
        last_name: "",
        phone: "",
        email: "",
      },
      data_projects: [],
      form_add_project: {
        id: "",
        name: "",
        maps: "",
        description: "",
      },
     
      project: {
        footage: 0,
        cuota:0,
        total: 0,
        discount: null,
        percentage_24: null,
        percentage_36: null,
      },
      data_streetblocks: [],
      form_add_streetblocks: {
        id: "",
        state: "",
      },
      data_lots: [],
      form_add_lots: {
        id: "",
        state: "",
      },
      sale: {
        date: "",
        departure_time: "",
        initial_fee: 0,
        date_initial_fee: "",
        contract: "",
      },
      data_numcuotas: [],
      form_add_cuotas: {
        id: "",
        state: "",
        amounttotal: '0.00',
        pending:0,
        amountcuota: '0.00',
      },
      data_amount_total: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
        item: [],
      },
    };
  },
  props: {
    item: Array,
  },
  computed: {
    cp_none: function () {
      return true;
    },
    btnClasses() {
      return [`btn btn-${this.color || "primary"}`];
    },
    button() {
      if (this.clients.dni != "" && this.form_add_cuotas.id > 0  && this.sale.contract != "") return false;
      return true;
    },
  },
  created() {
    this.mtd_initial();
    this.mtd_getdata_projects();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_initial: function () {
      this.mtd_getdata_clientes();

      this.form_add_cliente.id = 0;

      let actual = new Date();
      let fecha;
      fecha =
        actual.getFullYear() +
        "-" +
        ("0" + (actual.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + actual.getDate()).slice(-2);
      this.sale.date = fecha;
      this.sale.date_initial_fee = fecha;

      var h = ("0" + actual.getHours()).slice(-2);
      var m = ("0" + actual.getMinutes()).slice(-2);
      this.sale.departure_time = h + ":" + m;

      let arr = [];
      arr = [
        { value: 0, label: "N° Cuotas" },
        { value: 12, label: 12 },
        { value: 24, label: 24 },
        { value: 36, label: 36 },
      ];
      this.data_numcuotas = arr;
    },
    mtd_getdata_clientes: function () {
      this.get({
        url: this.$store.getters.get__url + "/client",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_clients = response.data_clients;
        })
        .catch((errors) => {});
    },
    load_data_client: function () {
      let client_id;
      client_id = this.form_add_cliente.id;
      this.get({
        url: this.$store.getters.get__url + "/client/" + client_id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.clients = response;
        })
        .catch((errors) => {});
    },
    load_data_client: function () {
      let client_id;
      client_id = this.form_add_cliente.id;
      this.get({
        url: this.$store.getters.get__url + "/client/" + client_id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.clients = response;
        })
        .catch((errors) => {});
    },
    btn_addclient: function () {
      this.modal.modal_form = true;
      this.modal.title = "Nuevo Cliente";
      this.modal.item = [];
    },
    mtd_action_client: function () {
      let cliente;
      cliente = {
        id: this.modal.item.id,
        name: this.modal.item.name,
        last_name: this.modal.item.last_name,
        dni: this.modal.item.dni,
        phone: this.modal.item.phone,
        email: this.modal.item.email,
        address: this.modal.item.address,
        description: this.modal.item.description,
        status: this.modal.item.status,
      };

      this.post({
        url: this.$store.getters.get__url + "/client/store",
        token: this.$store.getters.get__token,
        params: cliente,
      })
        .then((response) => {
          this.mtd_getdata_clientes();
          this.close_addclient();
          this.modal.item = [];

          response.forEach((element) => {
            this.clients.phone = element.Telefono;
            this.clients.dni = element.DNI;
            this.clients.email = element.Email;
            this.form_add_cliente.id = element.Id;
            this.vue.text_select = element.Nombre;
          });
        })
        .catch((errors) => {});
    },
    close_addclient: function () {
      this.modal.modal_form = false;
    },
    mtd_getdata_projects: function () {
      this.get({
        url: this.$store.getters.get__url + "/project",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_projects = response.data_project;
        })
        .catch((errors) => {});
    },
    load_streetblocks: function () {
      let project_id;
      project_id = this.form_add_project.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/projectstreetblock/" +
          project_id +
          "/showxproject",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_streetblocks = response.data;
          this.vue.estado1 = 0;
          this.limpiar();
          this.vue = {
            estado2: 1,
            estado3: 1,
            amount_discount: 0,
            amount_initial: 0,
            amount_pending: 0,
            change_discount: false,
            amounttotal_discount: 0,
          };
          this.vue.tipo_desc = "Descuento en S/.";
        })
        .catch((errors) => {});
    },
    load_lots: function () {
      let id;
      id = this.form_add_streetblocks.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/projectstreetblocklot/" +
          id +
          "/showxprojectstreetblock",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_lots = response.data;
          if (response.data.length >= 1) {
            this.vue.tipo_desc = "Descuento en S/.";
            this.vue.estado2 = 0;
          } else {
            this.vue.tipo_desc = "Descuento en S/.";
            this.limpiar();
            this.vue = {
              estado2: 1,
              total_discount: "",
              amount_initial: 0,
              amount_pending: 0,
              change_discount: 0,
              amounttotal_discount: 0,
            };
          }
        })
        .catch((errors) => {});
    },
    load_info: function () {
      let id;
      id = this.form_add_lots.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/projectstreetblocklot/" +
          id +
          "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
           if(response.state !=3){
      
           this.project.total=response.footage.amount;
           this.project.footage=response.footage.footage;
           this.project.percentage_24=response.footage.percentage_24;
           this.project.percentage_36=response.footage.percentage_36;
           this.vue.tipo_desc = 0;
            this.vue.change_discount = false;
            this.vue.estado3 = 0;
            this.vue.estado4 = 0;
             this.process();
          }else {
            bus.$emit("alert", {
              color: "danger",
              message: "LOTE SEPARADO/VENDIDO",
            });
          }
        })
        .catch((errors) => {});
    },
    change_type_discount: function () {
     this.vue.change_discount = ! this.vue.change_discount;
      this.vue.amount_discount=0;
      this.vue.amount_initial=0;
   
      this.process();
      
    },
    process: function () {
              /** descuento */
          let descuento=0;
          if (this.vue.change_discount == true) {
            descuento = parseFloat(this.project.total * this.vue.amount_discount / 100);
            this.project.discount=descuento;
          }else{
            descuento = parseFloat(this.vue.amount_discount);
             this.project.discount=descuento;
          }
          this.project.pending = parseFloat(this.project.total - this.project.discount - this.vue.amount_initial).toFixed(2);

       this.form_add_cuotas.amounttotal = parseFloat(this.project.total).toFixed(2);
        this.form_add_cuotas.pending=this.project.pending;       
        this.form_add_cuotas.amountcuota = parseFloat(this.form_add_cuotas.pending/12).toFixed(2);
    },
    load_montos:function(){
      let cuota_id,per24,per36,total24, total36;
      cuota_id=this.form_add_cuotas.id;
      per24=parseFloat(this.project.percentage_24);
      per36 = parseFloat(this.project.percentage_36);
      total24=this.project.total*(100+per24)/100;
      total36=this.project.total*(100+per36)/100;

      if (cuota_id == 0) {
        this.form_add_cuotas.amounttotal = parseFloat(0).toFixed(2);
        this.form_add_cuotas.pending=parseFloat(0).toFixed(2);
        this.form_add_cuotas.amountcuota = parseFloat(0).toFixed(2);
      }
            /** porcentaje por Mes12 */
      if (cuota_id == 12) {
        this.form_add_cuotas.amounttotal = parseFloat(this.project.total).toFixed(2);
        this.form_add_cuotas.pending=this.project.pending;       
        this.form_add_cuotas.amountcuota = parseFloat(this.form_add_cuotas.pending/this.form_add_cuotas.id).toFixed(2);
      }
      /** porcentaje por Mes24 */
      if (cuota_id == 24) {
        let mes24 = parseFloat(total24).toFixed(2);
        this.form_add_cuotas.amounttotal = parseFloat(mes24).toFixed(2);
        this.form_add_cuotas.pending=parseFloat(mes24-this.project.discount - this.vue.amount_initial).toFixed(2);
        this.form_add_cuotas.amountcuota = parseFloat(mes24 /  this.form_add_cuotas.id).toFixed(2);
      }
      /** porcentaje por Mes36 */
      if (cuota_id == 36) {
        let mes36 = parseFloat(total36).toFixed(2);
        this.form_add_cuotas.amounttotal = parseFloat(mes36).toFixed(2);
        this.form_add_cuotas.pending=parseFloat(mes36-this.project.discount - this.vue.amount_initial).toFixed(2);
        this.form_add_cuotas.amountcuota = parseFloat(mes36 / this.form_add_cuotas.id).toFixed(2);
      }
    },
   
    save_sale: function () {
      let elementos;

      elementos = {
        quotation_id: null,
        date: this.sale.date,
        time: this.sale.departure_time,
        client_id: this.form_add_cliente.id,
        user_id: this.$store.getters.get__user_id,
        project_streetblock_lot_id: this.form_add_lots.id,
        amount_total: this.form_add_cuotas.amounttotal,
        amount_initial: this.vue.amount_initial,
        type_discount: this.vue.change_discount,
        amount_discount: this.vue.amount_discount,
        amount_pending: this.form_add_cuotas.pending,
        date_initial: this.sale.date_initial_fee,
        contract: this.sale.contract,
        quotation: this.form_add_cuotas.id,
        state: 1,
      };

      this.post({
        url: this.$store.getters.get__url + "/sale/store",///quotation/storeSale",
        token: this.$store.getters.get__token,
        params: elementos,
      })
        .then((response) => {
            if (response.state == 0) {
              this.data_streetblocks = [];
              this.vue = {
                estado1: 1,
                estado2: 1,
                estado3: 1,
                estado4: 1,
                total_discount: "",
                total_initial: 0,
                total_pending: 0,
                totaltotal_discount: 0,
                change_discount: false,
                text_select: "Seleccione Cliente...",
                tipo_desc: "Descuento en S/.",
            };
            this.clients = {
                id: "",
                name: "",
                dni: "",
                last_name: "",
                phone: "",
                email: "",
            };
            this.limpiar();
            this.mtd_initial();
            this.sale.contract = "";

            bus.$emit("alert", {
                color: "success",
                message: response.message,
            });
            } else {
                bus.$emit("danger", {
                color: "success",
                message: response.message,
            });
            }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    limpiar: function () {
      this.vue.estado3 = 1;
      this.vue.estado4 = 1;
      this.vue.tipo_desc = "Descuento en S/.";
      this.data_amount_total = [];
      this.data_lots = [];
      this.project = {
        project_streetblock_lot_id: null,
        name: null,
        street: null,
        lot: null,
        footage: null,
        amount: null,
        discount: null,
        percentage_24: null,
        percentage_36: null,
      };
      this.form_add_cuotas.id = 0;
      this.form_add_cuotas.amounttotal = 0;
      this.form_add_cuotas.amountcuota = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.card-detail {
  background-color: #4db6ac;
}
</style>